import { REMOVE_NOTIFICATION, SHOW_NOTIFICATION } from './types';

export const showNotification = (message, type = 'info') => ({
  type: SHOW_NOTIFICATION,
  payload: {
    message,
    type,
  },
});

export const removeNotification = (notificationId) => ({
  type: REMOVE_NOTIFICATION,
  payload: {
    notificationId,
  },
});
