import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ParticipantsList.module.scss';

import { ASSISTANT } from 'app-constants/operation-participants-roles';
import { OperationStep } from 'common-types/library';
import { Participant } from 'common-types/operation';
import { UserBadge } from 'components/UserBadge/UserBadge';
import { WpaBadge } from 'components/Badge';
import { Text } from 'components/Typography/Text';

export interface ParticipantsListShortenedAssessment {
  title: string;
  operationSteps: { name: string }[];
}

interface ParticipantsListprops {
  assessments?: {
    [index: string]: ParticipantsListShortenedAssessment;
  };
  elements?: { [index: string]: OperationStep };
  participants: Participant[];
}

export const ParticipantsList: FC<ParticipantsListprops> = ({
  assessments,
  participants,
}) => {
  const { t } = useTranslation();
  if (!participants?.length) return null;

  return (
    <div>
      {participants
        .sort((a, b) => a.surname.localeCompare(b.surname))
        .map((participant) => {
          if (participant.role === ASSISTANT) {
            const { id: userId, givenName, surname, imageUrl } = participant;
            const wpa = assessments?.[userId];
            const opStep = wpa?.operationSteps?.[0];

            return (
              <div className={styles.participant} key={participant.id}>
                <div className={styles.user}>
                  <UserBadge
                    role="assistant"
                    givenName={givenName}
                    imageUrl={imageUrl}
                    surname={surname}
                    isSurnameFirst
                  />
                </div>

                {!!wpa && (
                  <>
                    <div className={styles.badge}>
                      <WpaBadge />
                    </div>

                    <div className={styles.description}>
                      <table className={styles.table}>
                        {!!opStep && (
                          <tr>
                            <Text
                              component="th"
                              variant="heading-md"
                              align="left"
                              className="pr-1"
                            >
                              {t('operationStep')}
                            </Text>
                            <Text component="td" variant="paragraph-md">
                              {opStep?.name}
                            </Text>
                          </tr>
                        )}

                        <tr>
                          <Text
                            component="th"
                            variant="heading-md"
                            align="left"
                            className="pr-1"
                          >
                            {t('Topic')}
                          </Text>
                          <Text component="td" variant="paragraph-md">
                            {wpa.title}
                          </Text>
                        </tr>
                      </table>
                    </div>
                  </>
                )}
              </div>
            );
          }

          return (
            <div key={participant.id} className="mb-1">
              <UserBadge
                givenName={participant.givenName}
                surname={participant.surname}
                imageUrl={participant.imageUrl}
                role={participant.role}
                isSurnameFirst
              />
            </div>
          );
        })}
    </div>
  );
};

ParticipantsList.displayName = 'ParticipantsList';
