import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export class ApiClient {
    private readonly axiosInstance: AxiosInstance;
    private accessToken: string | null = null;

    constructor(accessToken: string) {
        this.axiosInstance = axios.create({
            baseURL: 'BASE_URL as string',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    public async get<T>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<T>> {
        const accessToken = this.accessToken ?? localStorage.getItem('token');
        if (!accessToken) {
            throw new Error('No access token found');
        }
        return await this.axiosInstance.get<T, AxiosResponse<T>>(url, {
            ...config,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    public async post<T, R>(
        url: string,
        data?: T,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<R>> {
        const accessToken = this.accessToken ?? localStorage.getItem('token');
        if (!accessToken) {
            throw new Error('No access token found');
        }
        return await this.axiosInstance.post<T, AxiosResponse<R>>(url, data, {
            ...config,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    public async patch<T, R>(
        url: string,
        data?: T,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<R>> {
        const accessToken = this.accessToken ?? localStorage.getItem('token');
        if (!accessToken) {
            throw new Error('No access token found');
        }
        return await this.axiosInstance.patch<T, AxiosResponse<R>>(url, data, {
            ...config,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    public async delete<T>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<T>> {
        const accessToken = this.accessToken ?? localStorage.getItem('token');
        if (!accessToken) {
            throw new Error('No access token found');
        }
        return await this.axiosInstance.delete<T, AxiosResponse<T>>(url, {
            ...config,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    public async put<T, R>(
        url: string,
        data?: T,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<R>> {
        const accessToken = this.accessToken ?? localStorage.getItem('token');
        if (!accessToken) {
            throw new Error('No access token found');
        }
        return await this.axiosInstance.put<T, AxiosResponse<R>>(url, data, {
            ...config,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }
}
