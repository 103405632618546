import { APIv1 } from 'api/instance';
import { AssessmentList } from '../model/assessment';
import { QualificationGradingPeriodRequest } from '../model/qualification';
import { ApiClient } from './apiClient';

const BASE_URL = process.env.REACT_APP_API_URL + 'v1/assessments-filter/';

export class AssessmentService {
  private readonly apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  async getAssessmentsFilter(
    assessmentFormId: string,
    traineeId: string | undefined,
    gradingPeriod: QualificationGradingPeriodRequest | undefined
  ): Promise<AssessmentList> {
    const data = {
      assessmentFormId,
      traineeId,
      gradingPeriod,
    };
    const response = await APIv1.post<any, AssessmentList>(`${BASE_URL}`, data);
    return response;
  }
}
