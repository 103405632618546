import { OverflowText, Text } from 'components';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { RootState } from 'v2/app/store';
import { QualificationFormResponse } from 'v2/model/qualificationForm';

export const QualificationTitle: FC = () => {
  const { t } = useTranslation();
  const qualifications = useSelector(
    (state: RootState) => state.qualification.qualifications
  );

  const qualificationForms = useSelector(
    (state: RootState) => state.qualificationForm.qualificationForm
  );
  const { qualificationFormId, qualificationId } =
    useParams<{ qualificationFormId: string; qualificationId: string }>();

  const [pageTitle, setPageTitle] = useState<string>('');

  const getQualificationPageTitle = useCallback(() => {
    let title = t('qualification.qualificationTitle');

    if (qualifications)
      title = qualifications[0]?.title || qualifications.data[0]?.title;

    return title;
  }, [qualifications, t]);

  const getQualificationFormPageTitle = useCallback(() => {
    const qualification = (
      qualificationForms as unknown as QualificationFormResponse[]
    ).find((q) => q.id === qualificationFormId);

    return qualification?.title || t('qualification.qualificationTitle');
  }, [qualificationFormId, qualificationForms, t]);

  useEffect(() => {
    let title: string;

    if (!qualificationForms?.data && !qualificationId) {
      title = getQualificationFormPageTitle();
    } else title = getQualificationPageTitle();

    setPageTitle(title);
  }, [
    getQualificationFormPageTitle,
    getQualificationPageTitle,
    qualificationFormId,
    qualificationForms,
    qualificationId,
    qualifications,
    t,
  ]);

  return (
    <Text variant="heading-xl" className="mb-0">
      <OverflowText component="span" maxLines={1}>
        {pageTitle}
      </OverflowText>
    </Text>
  );
};
