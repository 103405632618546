import { ApiClient } from './apiClient';
import { AssessmentService } from './assessmentService';
import { QualificationFormService } from './qualificationFormService';
import { QualificationService } from './qualificationService';
import { UserService } from './userService';
import { AssessmentFormService } from './assessmentFormService';

// For now we are using token like this. enter manuel yor token in .env REACT_APP_TOKEN
localStorage.setItem('token', `${process.env.REACT_APP_TOKEN}`);

const accessToken = localStorage.getItem('token') as string;
const qualificationFormService = new QualificationFormService(
  new ApiClient(accessToken)
);
const qualificationService = new QualificationService(
  new ApiClient(accessToken)
);
const userService = new UserService(new ApiClient(accessToken));
const assessmentService = new AssessmentService(new ApiClient(accessToken));

const assessmentFormService = new AssessmentFormService(
  new ApiClient(accessToken)
);

export {
  qualificationFormService,
  qualificationService,
  userService,
  assessmentService,
  assessmentFormService,
};
