import cn from 'classnames';
import { MessageBlock, MessageType } from 'components';
import fastDeepEqual from 'fast-deep-equal';
import differenceBy from 'lodash/differenceBy';
import { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  removeNotification,
  selectNotifications,
} from 'store/modules/custom-notifications';
import classes from './NotificationsCenter.module.scss';

const DEFAULT_DISPLAY_DURATION = 5000; // 5s

type PropsFromRedux = ConnectedProps<typeof withConnect>;

interface Notification {
  id: number;
  message: string;
  type?: MessageType;
}

interface Props extends PropsFromRedux {
  notifications: Notification[];
  displayDuration?: number;
}

interface State {
  notifications: (Notification & { isShowed?: boolean })[];
  activeNotificationId: number | null;
}

class NotificationsCenter extends Component<Props, State> {
  static defaultProps = {
    notifications: [],
    displayDuration: DEFAULT_DISPLAY_DURATION,
  };

  constructor(props) {
    super(props);

    this.messageTimeout = undefined;
    this.state = {
      notifications: [],
      activeNotificationId: null,
    };
  }

  messageTimeout: number | undefined;

  static getDerivedStateFromProps(props, state) {
    const newMessages = differenceBy(
      props.notifications,
      state.notifications,
      'id'
    );

    if (!newMessages.length) return null;
    return { notifications: [...state.notifications, ...newMessages] };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (fastDeepEqual(this.state.notifications, nextProps.notifications)) {
      return false;
    }
    const updated =
      !fastDeepEqual(this.props.notifications, nextProps.notifications) ||
      nextState.activeNotificationId !== this.state.activeNotificationId;
    return updated;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.notifications.length &&
      prevProps.notifications !== this.props.notifications
    ) {
      this.showNextMessage();
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.messageTimeout);
  }

  showNextMessage = () => {
    const { activeNotificationId, notifications } = this.state;
    const activeNotification = notifications.find(
      (notification) => !notification.isShowed
    );

    if (!activeNotification) {
      if (activeNotificationId) {
        this.props.removeNotification(activeNotificationId); // The notification is no longer active, so I am removing it from the state.

        this.setState({ activeNotificationId: null });
      }
    } else {
      const nextState = {
        activeNotificationId: activeNotification.id,
        notifications: notifications.map((notification) => {
          if (notification.id === activeNotification.id) {
            return { ...notification, isShowed: true };
          }
          return notification;
        }),
      };
      this.setState(nextState);
      this.scheduleShowNextMessage();
    }
  };

  scheduleShowNextMessage() {
    const { displayDuration } = this.props;
    this.messageTimeout = window.setTimeout(
      this.showNextMessage.bind(this),
      displayDuration
    );
  }

  render() {
    const { notifications, activeNotificationId } = this.state;

    if (!notifications.length) return null;

    return notifications.map(({ message, type, id }) => (
      <MessageBlock
        key={id}
        type={type}
        className={cn(classes.notification, {
          [classes.show]: id === activeNotificationId,
        })}
        onClick={this.showNextMessage}
      >
        {message}
      </MessageBlock>
    ));
  }
}

const mapStateToProps = createStructuredSelector({
  notifications: selectNotifications,
});
const mapDispatchToProps = {
  removeNotification,
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(NotificationsCenter);
