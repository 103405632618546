import React, { FC, ReactNode, useState } from 'react';
import { usePopper } from 'react-popper';
import Downshift from 'downshift';
import { Options as PopperOptions } from '@popperjs/core';

import { DroplistContext, ListItempPropsGetter, RenderChildren } from './utils';

import styles from './DropList.module.scss';
import {
  Button,
  ButtonProps,
  ButtonSize,
  ButtonVariant,
} from 'components/Button';
import { Icon } from 'components/Icon';
import List from 'components/List/List';

interface DropListProps extends Partial<PopperOptions> {
  /**
   * React children prop
   */
  children: React.ReactNode | RenderChildren;
  /**
   * Droplist trigger button props
   */
  btnProps?: Partial<ButtonProps>;
  /**
   * Droplist trigger button class name
   */
  dropdownBtnClass?: string;
  /**
   * Droplist trigger button label
   */
  label?: ReactNode;
  /**
   * Droplist trigger button size
   */
  size?: ButtonSize;
  /**
   * Droplist trigger button style variant
   */
  variant?: ButtonVariant;
}

/**
 * Renders droplist component with trigger button
 */
export const DropList: FC<DropListProps> = ({
  children,
  dropdownBtnClass,
  btnProps,
  label,
  size = 'xs',
  variant = 'secondary',
  placement = 'bottom-start',
  ...options
}) => {
  const [refElement, setRefElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    refElement,
    popperElement,
    { placement, ...options }
  );

  return (
    <Downshift>
      {({ isOpen, getToggleButtonProps, getMenuProps, closeMenu }) => {
        const listItemProps: ListItempPropsGetter = () => ({
          clickable: true,
          onItemClickCapture: () => setTimeout(closeMenu, 0),
        });

        return (
          <span className={styles.root}>
            <DroplistContext.Provider value={listItemProps}>
              <Button
                size={size}
                variant={variant}
                {...btnProps}
                {...getToggleButtonProps({
                  ref: setRefElement,
                  className: dropdownBtnClass,
                })}
              >
                {label}
                <Icon
                  icon={isOpen ? 'chevron-up' : 'chevron-down'}
                  className={label ? 'ml-05' : ''}
                />
              </Button>

              {isOpen && children && (
                <List
                  data-placement={placement}
                  {...getMenuProps({
                    ref: setPopperElement,
                    className: styles.list,
                    style: popperStyles.popper,
                    ...popperAttributes.popper,
                  })}
                >
                  {typeof children === 'function'
                    ? (children as RenderChildren)(listItemProps)
                    : children}
                </List>
              )}
            </DroplistContext.Provider>
          </span>
        );
      }}
    </Downshift>
  );
};
