import { ApiClient } from './apiClient';
import {
  QualificationFormListResponse,
  QualificationFormSaveRequest,
  QualificationFormUpdateRequest,
} from '../model/qualificationForm';
import { APIv1 } from 'api/instance';

const BASE_URL = process.env.REACT_APP_API_URL + 'v1/qualification-form';

export class QualificationFormService {
  private readonly apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  async getQualificationForms(): Promise<QualificationFormListResponse> {
    try {
      const response = await APIv1.get<QualificationFormListResponse>(
        `${BASE_URL}/all`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }

  async getQualificationFormsById(
    id: string
  ): Promise<QualificationFormListResponse> {
    try {
      const response = await APIv1.get<QualificationFormListResponse>(
        `${BASE_URL}?id=${id}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }

  // check if posting qualification form is same as response from bac, or we need new type
  async postQualificationForm(
    data: QualificationFormSaveRequest
  ): Promise<QualificationFormListResponse> {
    return await APIv1.post(`${BASE_URL}/`, data);
  }

  async putQualificationForm(
    data: QualificationFormUpdateRequest
  ): Promise<QualificationFormListResponse> {
    return (await APIv1.put(`${BASE_URL}/`, data)).data;
  }

  async deleteQualificationForm(id: string): Promise<any> {
    return await APIv1.delete(`${BASE_URL}/${id}`);
  }
}
