import { REMOVE_NOTIFICATION, SHOW_NOTIFICATION } from './types';

export default (state = [], { type, payload }) => {
  switch (type) {
    case SHOW_NOTIFICATION: {
      return [...state, { id: Date.now(), ...payload }];
    }

    case REMOVE_NOTIFICATION: {
      return state.filter(
        (notification) => notification.id !== payload.notificationId
      );
    }

    default:
      return state;
  }
};
