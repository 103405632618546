import { Col, Row } from 'components';
import { FC } from 'react';
import { TitleMenu } from 'scenes/QualificationForm/TitleMenu';
import { Provider } from 'react-redux';
import { store } from 'v2/app/store';
import { QualificationTitle } from './QualificationTitle';

export const QualificationPageTitle: FC = () => {
  return (
    <Provider store={store}>
      <Row alignItems="center" justifyContent="space-between" nowrap>
        <Col>
          <Row alignItems="center" nowrap>
            <Col>
              <QualificationTitle />
            </Col>
            <Col auto>
              <TitleMenu />
            </Col>
          </Row>
        </Col>
      </Row>
    </Provider>
  );
};
