import { APIv1 } from 'api/instance';
import { UserList } from '../model/user';
import { ApiClient } from './apiClient';

const BASE_URL = process.env.REACT_APP_API_URL + 'v1/';

export class UserService {
  private readonly apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  async getAllUsersByInstituteId(id: string): Promise<UserList> {
    const response = await APIv1.get<any[]>(
      `${BASE_URL}/usersByInstituteId/${id}`
    );
    return response;
  }
  async getUserById(id: string): Promise<UserList> {
    const response = await APIv1.get<any>(`${BASE_URL}/users/${id}`);
    return response;
  }
}
