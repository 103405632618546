import cn from 'classnames';
import React, { Component, HTMLAttributes, ReactNode } from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import Downshift, { DownshiftProps } from 'downshift';
import noop from 'lodash/noop';

import styles from './Select.module.scss';
import { modifiers, itemToString, defaultSelectValue } from './utils';
import { SELECT_MODES } from './constants';
import { SelectInput } from './SelectInput/SelectInput';
import { SelectMenu } from './SelectMenu';

import { SelectOption } from 'common-types/common';
import withComponentDidCatch from 'containers/withComponentDidCatch';

export type SelectedValue = number | string;

interface SelectEvent {
  type: 'select';
  target: {
    name: string;
    value: SelectedValue;
  };
}

export interface SelectProps extends DownshiftProps<any> {
  id: string;
  name: string;
  value: SelectedValue;
  options: SelectOption[];
  autoFocus?: boolean;
  emptyMenuLabel?: string;
  error?: any;
  inputProps?: any;
  isLoading?: boolean;
  label?: string;
  mode?: 'select' | 'suggestions' | 'search';
  placeholder?: string;
  required?: boolean;
  selectInputAppend?: ReactNode;
  selectInputPrepend?: ReactNode;
  readOnly?: boolean;
  disableTextInput?: boolean;

  onChange?: (event: SelectEvent) => void;
  onReset?: () => void;
  onBlur?: () => void;
  selectValue?: (option: SelectOption) => SelectedValue;
  itemToString?: (option: SelectOption | null) => string;
  renderMenuAppend?: () => void;
  renderMenuPrepend?: () => void;
  renderOption?: () => ReactNode;
  closeMenu?: () => void;
}

export class Select extends Component<
  SelectProps & HTMLAttributes<HTMLSelectElement>
> {
  static defaultProps = {
    id: '',
    inputProps: {},
    isLoading: false,
    label: '',
    mode: SELECT_MODES.select,
    placeholder: null,
    required: false,
    readOnly: false,
    disableTextInput: false,
    onBlur: noop,
    onChange: noop,
    onSelect: noop,
    itemToString,
    renderOption: itemToString,
  };

  get selectedItem() {
    const { value, options, selectValue = defaultSelectValue } = this.props;
    if (!value || !options || !options.length) return null;
    const selectedItem = options.find(
      (option) => selectValue(option) === value
    );
    return selectedItem;
  }

  handleChange = (selectedItem) => {
    const { name, onChange, onReset, readOnly } = this.props;
    if (readOnly) return;

    if (onReset && !selectedItem) {
      onReset();
    } else {
      onChange &&
        onChange({
          type: 'select',
          target: {
            name,
            value: selectedItem && selectedItem.value,
          },
        });
    }
  };

  stateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.mouseUp:
      case Downshift.stateChangeTypes.blurInput:
        return {
          ...changes,
          inputValue: state.inputValue || state.selectedItem?.label || '',
        };
      case Downshift.stateChangeTypes.changeInput: {
        if (this.props.disableTextInput) {
          return {
            ...changes,
            inputValue: '',
          };
        }

        return changes;
      }
      default:
        return changes;
    }
  };

  render() {
    const {
      autoFocus,
      className,
      emptyMenuLabel,
      error,
      id,
      inputProps,
      isLoading,
      label,
      mode,
      options,
      placeholder,
      selectInputAppend,
      selectInputPrepend,
      value,
      name,
      required,
      readOnly,
      onChange,
      onReset,
      onBlur,
      renderMenuAppend,
      renderMenuPrepend,
      renderOption,
      itemToString,
      selectValue,
      closeMenu,

      ...downshiftProps
    } = this.props;

    return (
      <div className={cn('Select', styles[`${mode}-mode`])}>
        <Manager>
          <Downshift
            {...downshiftProps}
            id={id}
            itemToString={itemToString}
            onChange={this.handleChange}
            selectedItem={this.selectedItem}
            stateReducer={this.stateReducer}
          >
            {(downshiftStateProps) => {
              return (
                <div className={styles.container}>
                  {downshiftStateProps.isOpen && (
                    <Popper placement="bottom-start" modifiers={modifiers}>
                      {(popperProps) => {
                        const {
                          ref,
                          style,
                          placement,
                          update: updatePopper,
                        } = popperProps;
                        return (
                          <div
                            ref={ref}
                            style={style}
                            data-placement={placement}
                            className={styles.menuWrapper}
                          >
                            <SelectMenu
                              getItemProps={downshiftStateProps.getItemProps}
                              getMenuProps={downshiftStateProps.getMenuProps}
                              highlightedIndex={
                                downshiftStateProps.highlightedIndex
                              }
                              inputValue={downshiftStateProps.inputValue}
                              isOpen={downshiftStateProps.isOpen}
                              selectedItem={downshiftStateProps.selectedItem}
                              itemToString={itemToString}
                              required={required}
                              emptyMenuLabel={emptyMenuLabel}
                              error={error}
                              isLoading={isLoading}
                              mode={mode}
                              options={options}
                              renderMenuAppend={renderMenuAppend}
                              renderMenuPrepend={renderMenuPrepend}
                              renderOption={renderOption}
                              updatePopper={updatePopper}
                            />
                          </div>
                        );
                      }}
                    </Popper>
                  )}

                  <Reference>
                    {({ ref }) => (
                      <div ref={ref}>
                        <SelectInput
                          clearSelection={downshiftStateProps.clearSelection}
                          getInputProps={downshiftStateProps.getInputProps}
                          isOpen={downshiftStateProps.isOpen}
                          openMenu={downshiftStateProps.openMenu}
                          selectedItem={downshiftStateProps.selectedItem}
                          setState={downshiftStateProps.setState}
                          readOnly={readOnly}
                          autoFocus={autoFocus}
                          className={cn(
                            className,
                            readOnly && styles.readOnlySelect
                          )}
                          id={id}
                          mode={mode}
                          name={name}
                          onBlur={onBlur}
                          placeholder={placeholder}
                          selectInputAppend={selectInputAppend}
                          selectInputPrepend={selectInputPrepend}
                          {...inputProps}
                        />
                      </div>
                    )}
                  </Reference>
                </div>
              );
            }}
          </Downshift>
        </Manager>
      </div>
    );
  }
}

export default withComponentDidCatch('Select')(Select);
