import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { User, UserList } from '../model/user';
import { userService } from '../services/services';

const initialState = {
  users: null as UserList | null,
  user: null as User | null,
  status: 'idle',
  error: null as string | null,
};

export const fetchUsersByInsituteId = createAsyncThunk(
  'usersByInstituteId/fetch',
  (id: string) => {
    return userService.getAllUsersByInstituteId(id);
  }
);
export const fetchUserById = createAsyncThunk('userId/fetch', (id: string) => {
  return userService.getUserById(id);
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (boulder) => {
    boulder
      .addCase(fetchUsersByInsituteId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsersByInsituteId.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.users = action.payload;
      })
      .addCase(fetchUsersByInsituteId.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message ?? null;
      })
      .addCase(fetchUserById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.users = action.payload;
      })
      .addCase(fetchUserById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message ?? null;
      });
  },
});

export default userSlice.reducer;
