import React, { ReactElement, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'components/Grid';
import { Text } from 'components/Typography';
import { selectPatientRisksList } from 'store/modules/history';
import withRouterParams from 'containers/withRouterParams';
import { readOperationPatientRisks } from 'store/modules/operation-planning';
import { PatientRisks as PatientRisksI } from 'common-types/operation';
import { RouteComponentProps } from 'react-router';
import { NotFound } from 'components/NotFound';
import { OperationItem } from 'components/OperationItem';

const displayField = { key: 'mitigationAction' };

interface Props extends RouteComponentProps {
  patientRisks: PatientRisksI[];
  operationId: number;
}

const PatientRisksComponent = ({
  operationId,
  patientRisks,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(readOperationPatientRisks(operationId));
  }, []);

  if (!patientRisks?.length)
    return (
      <NotFound
        header={t('noPatientRisksAdded')}
        message=""
        withBackButton={false}
      ></NotFound>
    );

  return (
    <div>
      <Row className="justify-center">
        <Col auto basis="620px">
          <Text variant="title">{t('Risks to patients')}</Text>
          {patientRisks && patientRisks.length ? (
            patientRisks.map((risk, index) => (
              <OperationItem
                key={risk.id + index}
                item={risk}
                displayField={displayField}
                readonly
              />
            ))
          ) : (
            <p className="text-placeholder">{t('noRisksAdded')}</p>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = createStructuredSelector<Props, {}>({
  patientRisks: selectPatientRisksList,
});

const withConnect = connect(mapStateToProps);

const enhancer = compose(withRouterParams('operationId'), withConnect);

export const PatientRisks = enhancer(PatientRisksComponent);
