import { DropList, DropListItem } from 'components';
import { format } from 'date-fns';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'v2/app/store';
import useDownloadPdf from 'v2/hooks/useDownloadPDF';
import { User } from 'v2/model/user';
import { fetchUserById } from 'v2/reducers/userSlice';
import { qualificationService } from 'v2/services/services';

export const TitleMenu: FC = () => {
  const downloadPdf = useDownloadPdf();

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const qualifications = useSelector(
    (state: RootState) => state.qualification.qualifications
  );

  const handleDownload = async () => {
    if (!qualifications) return;

    const qualification = qualifications[0];
    const traineeId = qualification.traineeId;

    const trainee: User = (await dispatch(fetchUserById(traineeId)))
      .payload as User;

    let pdfName = `${trainee.givenName}_${trainee.surname}_${qualification.title}`;
    if (qualification.date) {
      const formattedDate = format(new Date(qualification.date), 'dd.MM.yyyy');
      pdfName += `_${formattedDate}`;
    }

    const pdfContent: any = await qualificationService.createPdf(
      qualification.id
    );

    downloadPdf(pdfContent, pdfName);
  };

  return (
    <DropList label={t('Options')} size="md">
      <DropListItem icon="download" onClick={handleDownload}>
        {t('Download')}
      </DropListItem>
    </DropList>
  );
};
