import { TABLE_DEFAULT_PAGE_SIZE } from 'app-constants';
import cn from 'classnames';
import { PanelBody } from 'components/Panel/PanelBody';
import withComponentDidCatch from 'containers/withComponentDidCatch';
import { noop } from 'lodash';
import React, { Component } from 'react';
import ReactTable from 'react-table';
import { calculatePages } from 'utils';
import { Pagination } from './Pagination';
import './react-table.css';
import { TableFixedHeader } from './TableFixedHeader';
import classes from './Table.module.scss';

// Link to the docs
// https://www.npmjs.com/package/react-table/v/6.11.4

class Table extends Component {
  static defaultProps = {
    columns: [],
    currentPage: 1,
    defaultPageSize: TABLE_DEFAULT_PAGE_SIZE,
    fixedHeader: false,
    notFoundHeader: '',
    notFoundMessage: '',
    onColHeaderClick: noop,
    pages: 1,
    showPagination: false,
    total: 0,
    noDataMessage: 'No data available',
  };

  get pages() {
    const { total, defaultPageSize } = this.props;

    if (total) return calculatePages(total, defaultPageSize);
    return 1;
  }

  get tableProps() {
    const {
      fixedHeader,
      defaultPageSize,
      columns,
      showPagination,
      total,
      pages,
      className,
      ...tableProps
    } = this.props;
    return tableProps;
  }

  render() {
    const {
      className,
      columns,
      currentPage,
      defaultPageSize,
      fixedHeader,
      onColHeaderClick,
      showPagination,
      headerClassName,
      noDataMessage,
    } = this.props;

    let params = new URL(document.location).searchParams;

    return (
      <div className={classes.container}>
        {!!fixedHeader && (
          <TableFixedHeader
            className={headerClassName}
            onColHeaderClick={onColHeaderClick}
            columns={columns}
            searchParams={{
              od: params.get('od'),
              ob: params.get('ob'),
            }}
          />
        )}
        <PanelBody key={currentPage}>
          <ReactTable
            className={cn(className, 'expand-0', {
              'fixed-header': fixedHeader,
            })}
            columns={columns}
            defaultPageSize={defaultPageSize}
            minRows={1}
            pages={this.pages}
            PaginationComponent={Pagination}
            resizable={false}
            showPageSizeOptions={false}
            showPagination={showPagination || false}
            sortable={false}
            NoDataComponent={() => {
              return <div className="rt-noData">{noDataMessage}</div>;
            }}
            {...this.tableProps}
          />
        </PanelBody>
      </div>
    );
  }
}
export default withComponentDidCatch('Table')(Table);
