const useDownloadPdf = () => {
  const downloadPdf = (pdfContent: any, pdfName: string) => {
    if (!pdfName.endsWith('.pdf')) pdfName += `.pdf`;

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(pdfContent);
    link.download = pdfName;
    link.click();
  };

  return downloadPdf;
};

export default useDownloadPdf;
