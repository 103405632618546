import { ApiClient } from './apiClient';
import { AssessmentFormList } from '../model/assessmentForm';
import { APIv1 } from '../../api/instance';

export class AssessmentFormService {
  private readonly apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  async getAssessmentForms(): Promise<AssessmentFormList> {
    return await APIv1.get<any[]>('/assessments/forms?range=[0, 100]');
  }
}
