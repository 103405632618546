import { configureStore } from '@reduxjs/toolkit';
import qualificationFormReducer from '../reducers/qualificationFormSlice';
import qualificationReducer from '../reducers/qualificationSlice';
import assessmentFormReducer from '../reducers/assessmentFormSlice';

export const store = configureStore({
  reducer: {
    qualificationForm: qualificationFormReducer,
    qualification: qualificationReducer,
    assessmentForm: assessmentFormReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: {
    name: 'store2',
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
