import { AssessmentFormList } from '../model/assessmentForm';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { assessmentFormService } from '../services/services';

const initialState = {
  assessmentForms: null as AssessmentFormList | null,
  status: 'idle',
  error: null as string | null,
};

export const fetchAssessmentForms = createAsyncThunk(
  'assessmentForm/fetch',
  () => {
    return assessmentFormService.getAssessmentForms();
  }
);

export const assessmentFormSlice = createSlice({
  name: 'assessmentForm',
  initialState,
  reducers: {},
  extraReducers: (boulder) => {
    boulder
      .addCase(fetchAssessmentForms.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAssessmentForms.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.assessmentForms = action.payload;
      })
      .addCase(fetchAssessmentForms.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message ?? null;
      });
  },
});

export default assessmentFormSlice.reducer;
